<template>
  <div>
    <div class="contents">
      <el-form
        :model="loginForm"
        ref="loginForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="账号" prop="userName">
          <el-input
            prefix-icon="el-icon-mobile"
            v-model="loginForm.userName"
            placeholder="请输入账号"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="psw">
          <div style="display: flex; align-items: center; position: relative">
            <el-input
              prefix-icon="el-icon-lock"
              :key="passwordType"
              ref="password"
              v-model="loginForm.psw"
              :type="passwordType"
              placeholder="请输入您的密码"
              name="ksPassword"
              tabindex="2"
              auto-complete="on"
            />
            <span class="el-icon-view" @click="showPwd"> </span>
          </div>
        </el-form-item>
      </el-form>
      <!-- <div class="gont1">账号</div>
      <div></div>
      <div class="gont1">密码</div> -->
    </div>
    <div class="btnBox">
      <div class="btn" @click="logins">绑 定</div>
    </div>
  </div>
</template>

<script>
import common from "@/api/common";
export default {
  data() {
    return {
      loginForm: {},
      passwordType: "password",
      rules: {
        userName: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        psw: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.loginForm.openid = this.$route.query.openid;
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    logins() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          common.handWxLogin(this.loginForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.href = "/phoneSuccess";
              }, 500);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.contents {
  background-color: #ffffff;
  padding: 20px;
}
.gont1 {
  font-size: 16px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.el-icon-view {
  position: absolute;
  right: 16px;
}
.btnBox {
  width: 100%;
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 30px;
}
.btn {
  width: 90%;
  height: 48px;
  background: #e01811;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}
</style>